/* eslint-disable react/prop-types */
import React from 'react';

export const SearchIcon = ({ stroke = 'black', ...props }) => (
	<svg 
		width="19" 
		height="19" 
		viewBox="0 0 19 19" 
		fill="none" 
		xmlns="http://www.w3.org/2000/svg"
	>
		<path 
			d="M13.3337 13.3347L17.9987 18.0007M15.3337 8.50671C15.3337 12.2777 12.2767 15.3347 8.50573 15.3347C4.73473 15.3347 1.67773 12.2777 1.67773 8.50671C1.67773 4.73571 4.73473 1.67871 8.50573 1.67871C12.2767 1.67871 15.3337 4.73571 15.3337 8.50671Z" 
			stroke={stroke} 
			strokeWidth="1.5" 
			strokeMiterlimit="10" 
			strokeLinecap="round" 
			strokeLinejoin="round"
			{...props}
		/>
	</svg>
);