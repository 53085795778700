import React, { FC, ReactNode } from 'react';

import { SettingsIcon } from 'components/Icons';
import { COLORS } from 'utils/consts';
import { BaseButton } from '../BaseButton/BaseButton';
import { MaterialUIButtonColorType, MaterialUIButtonSizeType } from 'models/types';
import { MaterialUISizeEnum } from 'models/enums';
import { MEDIUM_BORDER_RADIUS, SMALL_BORDER_RADIUS, SMALL_VARIANT_BUTTON_HEIGHT, SMALL_VARIANT_BUTTON_WIDTH } from 'utils/consts/themeConsts';

interface SettingsButtonInterface {
  size?: MaterialUIButtonSizeType;
  color?: MaterialUIButtonColorType;
  sx?: any;
  onClick?: () => void;
  children?: ReactNode | string;
}

export const SettingsButton: FC<SettingsButtonInterface> = ({ size = 'small', color = 'inherit', sx, onClick, children }) => {
	return (
		<BaseButton
			color={color}
			onClick={onClick}
			size={size}
			sx={{
				...sx,
				backgroundColor: sx?.backgroundColor || COLORS.white,
				borderRadius: MEDIUM_BORDER_RADIUS,
				height: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_BUTTON_HEIGHT : undefined,
				minWidth: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_BUTTON_WIDTH : undefined,
				gap: '10px',
				alignItems: 'center',
				padding: '4px 16px',
				// maxWidth: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_BUTTON_WIDTH : undefined,
			}}
		>
			<SettingsIcon stroke={sx?.color ? sx?.color : COLORS.darkGray} />
			{children}
		</BaseButton>
	);
};
