export const COLORS = {
	primary: '#2085FF',
	primaryDark: '#0059cb',
	primaryLight: '#70b4ff',
	primaryBlack: '#1C2228',
	background: '#EFF3F7',
	font: '#4D5A77',
	white: '#FFFFFF',
	black: '#212121',
	darkBlack: '#000',
	error: '#F44336',
	danger: '#A40000',
	gray: '#999999',
	lightGray: '#EEEEEE',
	mediumLightGray: '#CFCCD6',
	mediumGray: '#cccccc',
	blue: '#0494F1',
	lightBlueGray: '#CFD9E4',
	lightBlue: '#ECF5FF',
	lightTransparentBlue: 'rgba(4, 148, 241, .8)',
	transparentBlue: 'rgba(4, 148, 241, .1)',
	lightGreen: '#E8F5E9',
	lightRed: '#ffebee',
	red: '#E81B1B',
	success: '#4caf50',
	active: '#1DE6A8',
	iPhone: '#64B5F6',
	android: '#AED581',
	other: '#E0E0E0',
	darkGray: '#263238',
	inActiveGray: '#8a9db3',
	yellow: '#F9A825',
	teal: '#008080',
	tealLight: '#00a7a7',
	violet: '#8675FF',
	secondary: '#FFB949',
	lightAqua: '#E6F4FE',
	successGreen: '#2DAC00',
	darkTransparent: 'rgba(207, 204, 214, 0.50)',
	lightTransparent: 'rgba(207, 204, 214, 0.1)',
	orange: '#F87227',
	lightOrange: '#FFB949',
	lightCell: '#F6F6F7',
	placeholderGray: '#000000de',
};

export const ChartCountryPallete = {
	Australia: '#2f4f4f',
	Austria: '#556b2f',
	Belgium: '#a0522d',
	Bulgaria: '#483d8b',
	Croatia: '#008000',
	Cyprus: '#3cb371',
	Czechia: '#bda60d',
	'Czech Republic': '#bda60d',
	Denmark: '#9acd32',
	Estonia: '#20b2aa',
	Finland: '#00008b',
	France: '#32cd32',
	Germany: '#8b008b',
	Greece: '#b03060',
	Hungary: '#dd0000',
	Iceland: '#ff8c00',
	Ireland: '#ffd700',
	Italy: '#00ff00',
	Latvia: '#319DA0',
	Liechtenstein: '#00bfff',
	Lithuania: '#f4a460',
	Luxembourg: '#0000ff',
	Malta: '#a020f0',
	Netherlands: '#f08080',
	Norway: '#de6317',
	Poland: '#016A70',
	Portugal: '#d8bfd8',
	Romania: '#8C3333',
	Slovakia: '#6495ed',
	Slovenia: '#ff1493',
	Spain: '#7b68ee',
	Sweden: '#793FDF',
	'UK (Northern Ireland)': '#7fffd4',
	'United Kingdom': '#ffe4c4',
};
