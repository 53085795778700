import React, { FC, useCallback } from 'react';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useTextSearch } from 'hooks';
import { COLORS } from 'utils/consts';
import { BaseInput } from '../';

interface BaseInputInterface {
  onChange?: (x: any) => void;
  value?: any;
  name: string;
  label?: string;
  altLabel?: string;
  placeholder?: string;
  fullWidth?: boolean;
}

export const SearchInput: FC<BaseInputInterface> = ({
	onChange,
	value = '',
	fullWidth,
	label,
	altLabel,
	name,
	placeholder,
}) => {
	const {
		searchValue,
		onSearchChangeHandler
	} = useTextSearch(value);

	const submitHandler = useCallback((e)=>{
		e.preventDefault();
		onChange && onChange({
			target: {
				name,
				value: searchValue}
		});
	},[searchValue]);

	return (
		<form onSubmit={(e) => submitHandler(e)}>
			<BaseInput
				onChange={onSearchChangeHandler}
				onBlur={submitHandler}
				value={searchValue}
				name={name}
				label={label}
				altLabel={altLabel}
				placeholder={placeholder}
				fullWidth={fullWidth}
				radius='5px'
				inputProps={{
					sx: { 
						backgroundColor: COLORS.white, 
						borderRadius: '5px !important',
						'& .MuiInputBase-root, & fieldset': {
							borderRadius: '5px !important',
						},
						'& fieldset': {
							borderColor: '#CFCCD64D'
						}
					},
					startAdornment: (
						<InputAdornment 
							position='start' 
							component={'button'} 
							type="submit" 
							sx={{
								background: 'none', 
								border: 'none', 
								padding: 0, 
								cursor: 'pointer'
							}}
						>
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
		</form>
	);
};
