import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const useFullscreenParam = (tableId) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [isFullScreen, setIsFullScreen] = useState(false);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		// Set initial fullscreen state based on the URL parameter, using specific tableId
		setIsFullScreen(params.get('full') === tableId);
	}, [location.search, tableId]);

	const toggleFullScreen = () => {
		const params = new URLSearchParams(window.location.search);
		// Toggle fullscreen based on the specific tableId
		if (isFullScreen) {
			params.delete('full');  // Remove 'full' param to exit fullscreen
		} else {
			params.set('full', tableId); // Set the tableId as 'full' param
		}

		navigate({ search: params.toString() }, { replace: true });
		setIsFullScreen((prev) => !prev);
	};

	return { isFullScreen, toggleFullScreen };
};
