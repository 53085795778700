/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { getUserCompany, getUserMe, refreshToken } from 'services';

import { LocalStorageValuesEnum } from 'models/enums';
import { IUserType, CompanyType } from '../../models/types';
import { parseJwt } from 'utils';
import { UserContext } from './UserContext';

export const UserContextProvider = ({ children }) => {
	const [user, setUser] = useState<IUserType | null>(null);
	const [userCompany, setUserCompany] = useState<CompanyType | null>(null);

	const [userAuthenticating, setUserAuthenticating] = useState<boolean>(true);
	const [appIsDeploying, setAppIsDeploying] = useState<boolean>(false);

	const setUserData = useCallback(
		(userData: IUserType | null) => setUser(userData),
		[]
	);

	const onSigninUser = useCallback(async (signinRes) => {
		try {
			signinRes.access && localStorage.setItem(
				LocalStorageValuesEnum.ACCESS_TOKEN,
				signinRes.access
			);
			signinRes.refresh && localStorage.setItem(
				LocalStorageValuesEnum.REFRESH_TOKEN,
				signinRes.refresh
			);

			const userMeRes = await getUserMe(signinRes.access);
			if (userMeRes.is_admin) {
				const company = await getUserCompany(userMeRes?.company?.id);
				setUserCompany(company);
			}
			setUser(userMeRes);
			setAppIsDeploying(false);
		} catch (err) {
			// console.log('checking erro 1: ', err);
			// @ts-ignore
			if (!err.response) {
				setAppIsDeploying(true);
			}
		}
	}, []);

	const isTokenValid = (token: any) => {
		const tokenData = parseJwt(token);
		return tokenData.exp * 1000 - new Date().getTime() > 0;
	};

	useEffect(() => {
		async function persistUser() {
			const appAccessToken = localStorage.getItem(
				LocalStorageValuesEnum.ACCESS_TOKEN
			);
			const appRefreshToken = localStorage.getItem(
				LocalStorageValuesEnum.REFRESH_TOKEN
			);
			if (appAccessToken && isTokenValid(appAccessToken)) {
				await onSigninUser({ access: appAccessToken });
			} else if (appRefreshToken && isTokenValid(appRefreshToken)) {
				try {
					const refreshTokenData = await refreshToken({
						refresh: appRefreshToken,
					});
					await onSigninUser(refreshTokenData);
				} catch (error) {
					// console.log('checking erro 1: ', error);
					// @ts-ignore
					if (!error || !error.response) {
						setAppIsDeploying(true);
					}
				}
			} else {
				localStorage.clear();
			}
			setUserAuthenticating(false);
		}
		persistUser();
	}, []);

	const store = useMemo(
		() => ({
			user,
			userCompany,
			userAuthenticating,
			appIsDeploying,
			setUserData,
			setAppIsDeploying,
			onSigninUser,
		}),
		[
			user,
			userCompany,
			userAuthenticating,
			appIsDeploying,
			setUserData,
			setAppIsDeploying,
			onSigninUser,
		]
	);

	return <UserContext.Provider value={store}>{children}</UserContext.Provider>;
};
