import { CustomInputTypeEnum } from 'models/enums/CustomInputTypeEnum';
import { getMAHolders, getPackSizes, getPresentations } from 'services';

export const searchFilters = [
	{
		type: CustomInputTypeEnum.INGREDIENT_STRENGTH_COMBO,
		gridWrapper: 12,
		innerGridWrapper: 3,
		name: 'ingredient_and_strengths',
	},
	{
		type: CustomInputTypeEnum.AUTOCOMPLETE_REMOTE,
		gridWrapper: 3,
		name: 'presentation',
		label: 'Presentation',
		placeholder: 'Search presentations',
		dataService: getPresentations,
	},
	{
		type: CustomInputTypeEnum.AUTOCOMPLETE_REMOTE,
		gridWrapper: 3,
		name: 'pack_size',
		label: 'Pack Size',
		placeholder: 'Search pack sizes',
		dataService: getPackSizes,
	},
	{
		type: CustomInputTypeEnum.COUNTRY_AUTOCOMPLETE_STATIC,
		gridWrapper: 3,
		name: 'countries',
		label: 'Country',
		placeholder: 'Search country',
	},
	{
		type: CustomInputTypeEnum.AUTOCOMPLETE_REMOTE,
		gridWrapper: 3,
		name: 'ma_holder',
		label: 'MA Holder',
		placeholder: 'MA Holder',
		dataService: getMAHolders,
	},
];

export const activeIngSearchFilters = [
	{
		type: CustomInputTypeEnum.COUNTRY_AUTOCOMPLETE_STATIC,
		gridWrapper: 3,
		name: 'activeIngCountries',
		label: 'Country',
		placeholder: 'Search country',
	},
	{
		type: CustomInputTypeEnum.AUTOCOMPLETE_REMOTE,
		gridWrapper: 3,
		name: 'activeIngPresentation',
		label: 'Presentation',
		placeholder: 'Search presentation',
		dataService: getPresentations,
	},
	{
		type: CustomInputTypeEnum.SELECT,
		gridWrapper: 3,
		name: 'activeIngPack_size',
		label: 'Pack Size',
		placeholder: 'Search pack size',
	},
	{
		type: CustomInputTypeEnum.SELECT,
		gridWrapper: 3,
		name: 'activeIngStrength',
		label: 'strength',
		placeholder: 'Search strength',
	},
];
