import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, styled } from '@mui/material/';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { BaseButton } from 'components/Buttons';
import { COLORS } from 'utils/consts';
import { MEDIUM_BORDER_RADIUS } from 'utils/consts/themeConsts';

interface BaseAccordionProps {
  title: string | ReactNode;
  accordionActions?: { actionLabel: string; actionFn: () => void }[];
  children: ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  enableOverflow?: boolean;
  tableId?: string;
}

const AccordionWrapper = styled(Accordion)<{ fullWidth?: boolean }>(({ fullWidth }) => ({
	borderRadius: `${MEDIUM_BORDER_RADIUS} !important`,
	'& .MuiAccordionSummary-content.Mui-expanded': {
		margin: 0,
	},
	'& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
		minHeight: '48px',
	},
	'& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
		color: COLORS.primary,
	},
	sx: {
		padding: `14px ${fullWidth ? 0 : '10px'}`,
	},
}));

const AccordionTitle = styled(Typography)(() => ({
	fontSize: '16px',
	fontWeight: 600,
	fontFamily: 'Codec-Pro',
	alignSelf: 'center'
}));

export const BaseAccordion = ({enableOverflow = false, tableId, ...props}: BaseAccordionProps) => {
	const [expanded, setExpanded] = useState(false);

	const onToggleAccordion = () => setExpanded(!expanded);

	
	useEffect(() => {
		// makes accordion expanded if table inside must be expanded
		const params = new URLSearchParams(location.search);
		if(params.get('full') === tableId){
			setExpanded(true);
		}
	}, [location.search, tableId]);

	const onActionClick = (action: any, e: any) => {
		e.stopPropagation();
		action();
	};

	return (
		<AccordionWrapper 
			onChange={onToggleAccordion} 
			expanded={expanded} 
			disabled={props.disabled} 
			elevation={0}
			sx={{
				'& .MuiCollapse-root': {
					position: 'relative',
				}
			}}
		>
			<AccordionSummary
				expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
				aria-controls='panel1a-content'
				id='panel1a-header'
				sx={{
					paddingTop: expanded ? '20px' : 0,
				}}
			>
				<AccordionTitle>
					{props.title}
				</AccordionTitle>
				<Box px={2} ml={'auto'}>
					{props.accordionActions && props.accordionActions.map((action, index) => (
						<Box ml={2} key={index}>
							<BaseButton label={action.actionLabel} onClick={onActionClick.bind(null, action.actionFn)} />
						</Box>
					))}
				</Box>
			</AccordionSummary>
			<AccordionDetails 
				sx={props.fullWidth ? { 
					width: '100%', 
					padding: 0,
					...(enableOverflow && {
						'& > div': { 
							overflow: 'visible' 
						} 
					})
				} : {
					...(enableOverflow && {
						'& > div': { 
							overflow: 'visible' 
						}
					})
				}}
			>
				{props.children}
			</AccordionDetails>
		</AccordionWrapper>
	);
};
