import React from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { Grid, Box, Link, Typography, Alert } from '@mui/material';

import { useUser } from 'context';

import { BaseButton } from 'components/Buttons';
import { BaseInput, BasePasswordInput } from 'components/Inputs';
import { signinSchema, initialFormValues } from 'forms/signIn';
import { signIn } from 'services';
import { AlertsTypesEnum, ApplicationRoutesEnum } from 'models/enums';
import { ResponseMessageInterface } from 'models/interfaces';

export const SignIn = () => {
	const { onSigninUser, setAppIsDeploying} = useUser();
	const navigate = useNavigate();
	const location = useLocation();
	const [responseMessage, setResponseMessage] = useState<ResponseMessageInterface | null>(null);
	const onSignInHandler = async (signInInputs: any, { setSubmitting, resetForm }: any) => {
		try {
			setResponseMessage(null);
			const response = await signIn(signInInputs);
			await onSigninUser(response);
			resetForm();

			if (location.state && location.state.from) {
				navigate(location.state.from + location.state.search);
			} else {
				navigate(ApplicationRoutesEnum.HOME);
			}
		} catch (err: any) {
			// console.log('checking erro 3: ', err);
			if(!err){
				setAppIsDeploying(true);
				navigate(ApplicationRoutesEnum.HOME);
			}else{
				setResponseMessage({ type: AlertsTypesEnum.ERROR, detail: err.data.detail });
			}
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Box>
			<Typography align="left" sx={{ mb: 4 }}>
        Sign In to your account to continue.
			</Typography>
			<Formik initialValues={initialFormValues} validationSchema={signinSchema} onSubmit={onSignInHandler}>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<BaseInput
									fullWidth
									name="email"
									label="Enter Email"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.email && Boolean(errors.email)}
									helperText={touched.email ? errors.email : ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<BasePasswordInput
									fullWidth
									name="password"
									label="Enter Password"
									autoComplete="current-password"
									value={values.password}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.password && Boolean(errors.password)}
									helperText={touched.password ? errors.password : ''}
								/>
								<Box width={1} display="flex" pt={1} justifyContent="flex-end">
									<Link variant="body2" component={RouterLink} to={ApplicationRoutesEnum.FORGOT_PASSWORD}>
                    Forgot your Password?
									</Link>
								</Box>
							</Grid>
							{responseMessage && responseMessage.detail && (
								<Grid item xs={12}>
									<Alert severity={responseMessage.type}>{responseMessage.detail}</Alert>
								</Grid>
							)}
							{}
							<Grid item xs={12} sx={{ textAlign: 'center' }}>
								<BaseButton 
									disabled={isSubmitting} 
									label={'Sign In'} 
									startIcon={<></>}
									loading={isSubmitting} 
								/> 
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};
