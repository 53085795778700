import { useEffect, useRef, useState } from 'react';

export function useDebounce<T>(value: T, delay?: number): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 300);
		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);


	return debouncedValue;
}

export function useDebounceObject<T extends Record<number | string, any>>(
	value: T,
	delay = 300
): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);
	const previousValue = useRef<T>(value);

	useEffect(() => {
		const timer = setTimeout(() => {
			const isEqual = JSON.stringify(value) === JSON.stringify(previousValue.current);

			if (!isEqual) {
				setDebouncedValue(value);
				previousValue.current = value;
			}
		}, delay);

		return () => clearTimeout(timer);
	}, [value, delay]);

	return debouncedValue;
}
